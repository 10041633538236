import {AxiosResponse} from "axios";
import {FeedbackPage} from "migration/entities/Feedback";
import {LangsList, MlString} from "migration/entities/base/mlString";
import {FeedbackPageId, FeedbackPageName, FeedbackPageSlug, OrgId} from "migration/entities/baseAliases";
import {$apiV2, $apiV2FormData, $apiV4, $apiV4FormData} from "migration/shared/api";
import {ApiRoutes} from "migration/shared/api/api-routes";
import {ErrorResponse, SuccessResponse} from "migration/shared/api/response/response";

export interface getFeedbackPageByIdReq {
    id: FeedbackPageId;
}

export interface cmdChangeFeedbackPageSlugReq {
    feedbackPageId: FeedbackPageId;
    slug: FeedbackPageSlug;
}

export interface cmdRenameFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    name: FeedbackPageName;
}

export interface cmdSetDefaultBackLinkFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    backLink: string;
}

export interface cmdChooseLangsFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    defaultLang: LangsList;
    otherLangs: LangsList[];
}

export interface cmdSaveTextsFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    title: MlString;
    subtitle: MlString;
}

export interface cmdAddGradeReasonFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    reasonId: string;
    reasonName: MlString;
}

export interface cmdEditGradeReasonFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    reasonId: string;
    reasonName: MlString;
}

export interface cmdRemoveGradeReasonFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    reasonId: string;
}

export interface cmdSaveGradeTyTextsFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    TYTitle: MlString;
    TYSubtitle: MlString;
}

export interface cmdAddGradeTyLinkFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    TYLinkId: string;
    TYLinkcaption: MlString;
    TYLinkText: MlString;
    TYLinkURL: string;
}

export interface cmdEditGradeTyLinkFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    TYLinkId: string;
    TYLinkcaption: MlString;
    TYLinkText: MlString;
    TYLinkURL: string;
}

export interface cmdRemoveGradeTyLinkFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    TYLinkId: string;
}

export interface cmdAttachImageToGradeTyLinkFeedbackPageReq {
    TYLinkId: string;
    TYLinkImgFileId: string;
    TYLinkImgFilename: string;
    feedbackPageId: FeedbackPageId;
}

export interface cmdDetachImageFromGradeTyLinkFeedbackPageReq {
    feedbackPageId: FeedbackPageId;
    TYLinkId: string;
    TYLinkImgFileId: string;
    TYLinkImgFilename: string;
}

export interface cmdDetachImageFromGradeTyLinkFeedbackPageReqV4 {
    feedbackPageId: FeedbackPageId;
    tyLinkId: string;
    grade: string
}

export default class FeedbackEditService {
    // ==================================================== UPLOAD =====================================================
    static async uploadFeedbackPageTyLinkImg(request: FormData, orgId: OrgId): Promise<AxiosResponse<SuccessResponse<{fileId: string, filename: string}> | ErrorResponse>> {
        return $apiV2FormData(orgId).post<SuccessResponse<{fileId: string, filename: string}> | ErrorResponse>(ApiRoutes.UPLOAD_FEEDBACK_PAGE_TY_LINK_IMG, request);
    }

    // ==================================================== QUERIES ====================================================

    static async getFeedbackPageById(request: getFeedbackPageByIdReq, orgId: OrgId, controller: AbortController): Promise<AxiosResponse<SuccessResponse<FeedbackPage> | ErrorResponse>> {
        return $apiV2(orgId).post<SuccessResponse<FeedbackPage> | ErrorResponse>(ApiRoutes.FEEDBACK_PAGE_BY_ID, request, {signal: controller.signal});
    }

    // ==================================================== COMMANDS ===================================================

    static async cmdChangeFeedbackPageSlug(request: cmdChangeFeedbackPageSlugReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.CHANGE_FEEDBACK_PAGE_SLUG, request);
    }

    static async cmdRenameFeedbackPage(request: cmdRenameFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.RENAME_FEEDBACK_PAGE, request);
    }

    static async cmdSetDefaultBackLinkFeedbackPage(request: cmdSetDefaultBackLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.SET_DEFAULT_BACK_LICK_FEEDBACK_PAGE, request);
    }

    static async cmdChooseLangsForFeedbackPage(request: cmdChooseLangsFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.CHOOSE_LANGS_FOR_FEEDBACK_PAGE, request);
    }

    static async cmdSaveTextsForFeedbackPage(request: cmdSaveTextsFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.SAVE_TEXTS_FOR_FEEDBACK_PAGE, request);
    }

    // =================================================================================================================
    // =================================== Positive grade grade reason feedback page ===================================
    // =================================================================================================================

    static async cmdAddPositiveGradeReasonFeedbackPage(request: cmdAddGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ADD_POSITIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdEditPositiveGradeReasonFeedbackPage(request: cmdEditGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_POSITIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdRemovePositiveGradeReasonFeedbackPage(request: cmdRemoveGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_POSITIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdSavePositiveGradeTyTextsFeedbackPage(request: cmdSaveGradeTyTextsFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.SAVE_POSITIVE_GRADE_TY_TEXTS_FEEDBACK_PAGE, request);
    }

    static async cmdAddPositiveGradeTyLinkFeedbackPage(request: cmdAddGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ADD_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdEditPositiveGradeTyLinkFeedbackPage(request: cmdEditGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdRemovePositiveGradeTyLinkFeedbackPage(request: cmdRemoveGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdAttachImageToPositiveGradeTyLinkFeedbackPage(request: cmdAttachImageToGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ATTACH_IMAGE_TO_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdDetachImageFromPositiveGradeTyLinkFeedbackPage(request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.DETACH_IMAGE_FROM_POSITIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }


    // =================================================================================================================
    // =================================== Negative grade grade reason feedback page ===================================
    // =================================================================================================================

    static async cmdAddNegativeGradeReasonFeedbackPage(request: cmdAddGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ADD_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdEditNegativeGradeReasonFeedbackPage(request: cmdEditGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdRemoveNegativeGradeReasonFeedbackPage(request: cmdRemoveGradeReasonFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_NEGATIVE_GRADE_REASON_FEEDBACK_PAGE, request);
    }

    static async cmdSaveNegativeGradeTyTextsFeedbackPage(request: cmdSaveGradeTyTextsFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.SAVE_NEGATIVE_GRADE_TY_TEXTS_FEEDBACK_PAGE, request);
    }

    static async cmdAddNegativeGradeTyLinkFeedbackPage(request: cmdAddGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ADD_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdEditNegativeGradeTyLinkFeedbackPage(request: cmdEditGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdRemoveNegativeGradeTyLinkFeedbackPage(request: cmdRemoveGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdAttachImageToNegativeGradeTyLinkFeedbackPage(request: cmdAttachImageToGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.ATTACH_IMAGE_TO_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdDetachImageFromNegativeGradeTyLinkFeedbackPage(request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, orgId: OrgId) {
        return $apiV2(orgId).post(ApiRoutes.DETACH_IMAGE_FROM_NEGATIVE_GRADE_TY_LINK_FEEDBACK_PAGE, request);
    }

    // =================================================================================================================
    // =================================== Specific grade grade reason feedback page ===================================
    // =================================================================================================================

    static async cmdAddSpecificGradeGradeReasonFeedbackPage(request: cmdAddGradeReasonFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.ADD_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE, {...request, grade: grade});
    }

    static async cmdEditSpecificGradeGradeReasonFeedbackPage(request: cmdEditGradeReasonFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE, {...request, grade: grade});
    }

    static async cmdRemoveSpecificGradeGradeReasonFeedbackPage(request: cmdRemoveGradeReasonFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_SPECIFIC_GRADE_GRADE_REASON_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdSaveSpecificGradeGradeTyTextsFeedbackPage(request: cmdSaveGradeTyTextsFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.SAVE_SPECIFIC_GRADE_GRADE_TY_TEXTS_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdAddSpecificGradeGradeTyLinkFeedbackPage(request: cmdAddGradeTyLinkFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.ADD_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE, {...request, grade: grade});
    }

    static async cmdEditSpecificGradeGradeTyLinkFeedbackPage(request: cmdEditGradeTyLinkFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.EDIT_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdRemoveSpecificGradeGradeTyLinkFeedbackPage(request: cmdRemoveGradeTyLinkFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.REMOVE_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdAttachImageToSpecificGradeGradeTyLinkFeedbackPage(request: cmdAttachImageToGradeTyLinkFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.ATTACH_IMAGE_TO_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdDetachImageFromSpecificGradeGradeTyLinkFeedbackPage(request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, orgId: OrgId, grade: number) {
        return $apiV2(orgId).post(ApiRoutes.DETACH_IMAGE_FROM_SPECIFIC_GRADE_GRADE_TY_LINK_FEEDBACK_PAGE, {
            ...request,
            grade: grade
        });
    }

    static async cmdAttachImageToTyLinkFeedbackPage(request: FormData, orgId: OrgId) {
        return $apiV4FormData(orgId).post(ApiRoutes.ATTACH_IMAGE_TO_TY_LINK_FEEDBACK_PAGE, request);
    }

    static async cmdDetachImageFromTyLinkFeedbackPage(request: cmdDetachImageFromGradeTyLinkFeedbackPageReqV4, orgId: OrgId) {
        return $apiV4(orgId).post(ApiRoutes.DETACH_IMAGE_TO_TY_LINK_FEEDBACK_PAGE, request);
    }
}
