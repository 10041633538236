import {
    FeedbackActionEnum,
    SetFeedbackPageAction,
    SetIsLoadingGetFeedbackPageByIdAction,
} from "./types";
import {AppDispatch, RootState} from "migration/app/store";
import {MlString} from "migration/entities/base/mlString";
import {FeedbackPageId} from "migration/entities/baseAliases";
import {NavigateCallback} from "migration/entities/base/navigateCallback";
import {FeedbackPage, GradeSettings, Reason, TYLink} from "migration/entities/Feedback";
import {txt} from "migration/shared/lib/core/i18ngen";
import {Notify} from "migration/shared/lib/notification/notification";
import {HttpActionCreator, HttpActionCreatorWithResponse} from "migration/shared/lib/http-handler/httpHandler";
import FeedbackEditService, {
    cmdAddGradeReasonFeedbackPageReq,
    cmdChangeFeedbackPageSlugReq,
    cmdChooseLangsFeedbackPageReq,
    cmdEditGradeReasonFeedbackPageReq,
    cmdRemoveGradeReasonFeedbackPageReq,
    cmdRenameFeedbackPageReq, cmdSaveGradeTyTextsFeedbackPageReq,
    cmdSaveTextsFeedbackPageReq,
    cmdSetDefaultBackLinkFeedbackPageReq,
    cmdAddGradeTyLinkFeedbackPageReq,
    cmdEditGradeTyLinkFeedbackPageReq,
    cmdRemoveGradeTyLinkFeedbackPageReq,
    cmdAttachImageToGradeTyLinkFeedbackPageReq,
    cmdDetachImageFromGradeTyLinkFeedbackPageReq, cmdDetachImageFromGradeTyLinkFeedbackPageReqV4,
} from "../api/feedbackEditService";
import {getCookie} from "../../../../../shared/lib/cookie";

export const FeedbackActionCreators = {
    setFeedbackPage: (feedbackPage: FeedbackPage): SetFeedbackPageAction => ({
        type: FeedbackActionEnum.SET_FEEDBACK_PAGE,
        payload: feedbackPage
    }),
    setIsLoadingGetFeedbackPageById: (isLoading: boolean): SetIsLoadingGetFeedbackPageByIdAction => ({
        type: FeedbackActionEnum.SET_IS_LOADING_GET_FEEDBACK_PAGE_BY_ID,
        payload: isLoading
    }),

    // ==================================================== QUERIES ====================================================

    getFeedbackPageById: (id: FeedbackPageId, navigationCallback: NavigateCallback, controller: AbortController) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreatorWithResponse(dispatch, currentLang, FeedbackEditService.getFeedbackPageById({id: id}, currentUser?.org?.id || "", controller), {
            navigateCallback: navigationCallback,
            loadingCallback: FeedbackActionCreators.setIsLoadingGetFeedbackPageById,
            successCallback: (res) => {
                const specificGrading: {[key: number]: GradeSettings} = {};
                Object.keys(res.data.data?.specificGrading || {}).forEach(item => {
                    const grade = Number(item);
                    if (isNaN(grade)) return;
                    if (grade < 1 || grade > 5) return;
                    specificGrading[grade] = {
                        TYText: {
                            TYTitle: res.data.data.specificGrading[grade]?.TYText?.TYTitle || {} as MlString,
                            TYSubtitle: res.data.data.specificGrading[grade]?.TYText?.TYSubtitle || {} as MlString,
                        },
                        TYLink: res.data.data.specificGrading[grade]?.TYLink || [] as TYLink[],
                        reasons: res.data.data.specificGrading[grade]?.reasons || [] as Reason[],
                    }
                });

                dispatch(FeedbackActionCreators.setFeedbackPage({
                    ...res.data.data,
                    text: {
                        title: res.data.data.text?.title || {} as MlString,
                        subtitle: res.data.data.text?.subtitle || {} as MlString,
                    },
                    positiveGrading: {
                        TYText: {
                            TYTitle: res.data.data.positiveGrading?.TYText?.TYTitle || {} as MlString,
                            TYSubtitle: res.data.data.positiveGrading?.TYText?.TYSubtitle || {} as MlString,
                        },
                        TYLink: res.data.data.positiveGrading?.TYLink || [] as TYLink[],
                        reasons: res.data.data.positiveGrading?.reasons || [] as Reason[],
                    },
                    negativeGrading: {
                        TYText: {
                            TYTitle: res.data.data.negativeGrading?.TYText?.TYTitle || {} as MlString,
                            TYSubtitle: res.data.data.negativeGrading?.TYText?.TYSubtitle || {} as MlString,
                        },
                        TYLink: res.data.data.negativeGrading?.TYLink || [] as TYLink[],
                        reasons: res.data.data.negativeGrading?.reasons || [] as Reason[],
                    },
                    specificGrading: specificGrading,
                } || {} as FeedbackPage));
            },
        });
    },

    // ==================================================== COMMANDS ===================================================

    changeFeedbackPageSlug: (request: cmdChangeFeedbackPageSlugReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdChangeFeedbackPageSlug(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    renameFeedbackPage: (request: cmdRenameFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRenameFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    setDefaultBackLinkFeedbackPage: (request: cmdSetDefaultBackLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdSetDefaultBackLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    chooseLangsForFeedbackPage: (request: cmdChooseLangsFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdChooseLangsForFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    saveTextsForFeedbackPage: (request: cmdSaveTextsFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdSaveTextsForFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    // =================================================================================================================
    // =================================== Positive grade grade reason feedback page ===================================
    // =================================================================================================================

    addPositiveGradeReasonFeedbackPage: (request: cmdAddGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddPositiveGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editPositiveGradeReasonFeedbackPage: (request: cmdEditGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditPositiveGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removePositiveGradeReasonFeedbackPage: (request: cmdRemoveGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemovePositiveGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    savePositiveGradeTyTextsFeedbackPage: (request: cmdSaveGradeTyTextsFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdSavePositiveGradeTyTextsFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    addPositiveGradeTyLinkFeedbackPage: (request: cmdAddGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddPositiveGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editPositiveGradeTyLinkFeedbackPage: (request: cmdEditGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditPositiveGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removePositiveGradeTyLinkFeedbackPage: (request: cmdRemoveGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemovePositiveGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    attachImageToPositiveGradeTyLinkFeedbackPage: (request: cmdAttachImageToGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAttachImageToPositiveGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                Notify.Success({title: txt.upload_image_success[currentLang], message: ""});
            },
        });
    },

    detachImageFromPositiveGradeTyLinkFeedbackPage: (request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdDetachImageFromPositiveGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    // =================================================================================================================
    // =================================== Negative grade grade reason feedback page ===================================
    // =================================================================================================================

    addNegativeGradeReasonFeedbackPage: (request: cmdAddGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddNegativeGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editNegativeGradeReasonFeedbackPage: (request: cmdEditGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditNegativeGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removeNegativeGradeReasonFeedbackPage: (request: cmdRemoveGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemoveNegativeGradeReasonFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    saveNegativeGradeTyTextsFeedbackPage: (request: cmdSaveGradeTyTextsFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdSaveNegativeGradeTyTextsFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    addNegativeGradeTyLinkFeedbackPage: (request: cmdAddGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddNegativeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editNegativeGradeTyLinkFeedbackPage: (request: cmdEditGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditNegativeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removeNegativeGradeTyLinkFeedbackPage: (request: cmdRemoveGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemoveNegativeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    attachImageToNegativeGradeTyLinkFeedbackPage: (request: cmdAttachImageToGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAttachImageToNegativeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                Notify.Success({title: txt.upload_image_success[currentLang], message: ""});
            },
        });
    },

    detachImageFromNegativeGradeTyLinkFeedbackPage: (request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdDetachImageFromNegativeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    // =================================================================================================================
    // =================================== Specific grade grade reason feedback page ===================================
    // =================================================================================================================

    addSpecificGradeReasonFeedbackPage: (request: cmdAddGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddSpecificGradeGradeReasonFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editSpecificGradeReasonFeedbackPage: (request: cmdEditGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditSpecificGradeGradeReasonFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removeSpecificGradeReasonFeedbackPage: (request: cmdRemoveGradeReasonFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemoveSpecificGradeGradeReasonFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    saveSpecificGradeTyTextsFeedbackPage: (request: cmdSaveGradeTyTextsFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdSaveSpecificGradeGradeTyTextsFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    addSpecificGradeTyLinkFeedbackPage: (request: cmdAddGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAddSpecificGradeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    editSpecificGradeTyLinkFeedbackPage: (request: cmdEditGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdEditSpecificGradeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    removeSpecificGradeTyLinkFeedbackPage: (request: cmdRemoveGradeTyLinkFeedbackPageReq, feedbackPage: FeedbackPage, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdRemoveSpecificGradeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: (feedbackPage: FeedbackPage) => {
                dispatch(FeedbackActionCreators.setFeedbackPage(feedbackPage));
            },
        }, feedbackPage);
    },

    attachImageToSpecificGradeTyLinkFeedbackPage: (request: cmdAttachImageToGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAttachImageToSpecificGradeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                Notify.Success({title: txt.upload_image_success[currentLang], message: ""});
            },
        });
    },

    detachImageFromSpecificGradeTyLinkFeedbackPage: (request: cmdDetachImageFromGradeTyLinkFeedbackPageReq, navigationCallback: NavigateCallback, grade: number) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdDetachImageFromSpecificGradeGradeTyLinkFeedbackPage(request, currentUser?.org?.id || "", grade), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },

    attachImageToTyLinkFeedbackPage: (request: FormData, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;

        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdAttachImageToTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {
                Notify.Success({title: txt.upload_image_success[currentLang], message: ""});
            },
        });
    },

    detachImageFromTyLinkFeedbackPage: (request: cmdDetachImageFromGradeTyLinkFeedbackPageReqV4, navigationCallback: NavigateCallback) => async (dispatch: AppDispatch, getState: () => RootState) => {
        const {currentLang} = getState().lang;
        const {currentUser} = getState().user;
        await HttpActionCreator(dispatch, currentLang, FeedbackEditService.cmdDetachImageFromTyLinkFeedbackPage(request, currentUser?.org?.id || ""), {
            navigateCallback: navigationCallback,
            mainCallback: () => {

            },
        });
    },
}
